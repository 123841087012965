





























































































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component
export default class RoadMap extends Vue {
  @Prop({ default: 2 }) step!: number
  @Inject({}) vm!: MintingViewModel

  // get whitelistStatus() {
  //   return this.vm.whitelistStatus
  // }
}
